<template>

  <div class="mx-2 w-full" style="height: 100vh!important;">
    <iframe :src="`${document_src}`" width="100%" style="height: 100vh!important;" frameborder="0" />
  </div>
</template>

<script>

export default {
  props: {
    documentID: String,
  },
  data() {
    return {
      file_source: '',
      document_type: null,
      document_src: null,
      document_title: '',
      view_document_popup: false,
    }
  },
  async created() {
    this.fetchDocument()
  },
  methods: {
    async fetchDocument() {
      let docPath
      let docURL = null

      switch (this.document_id) {
        case 'loa':
          docPath = 'media/e2i_loa.docx'
          break
        case 'privacy_policy':
          docPath = 'media/privacy-policy.docx'
          break
        default:
          docPath = 'media/privacy-policy.docx'
      }

      docURL = await this.$generateDownloadUrl(docPath)
      this.viewDocument(docPath, docURL, this.documentID)
    },

    viewDocument(file = null, fileUrl = null, fileType = 'resume') {
      this.document_type = file?.toLowerCase().endsWith('pdf') ? 'pdf' : 'docs';
      this.document_src = `https://docs.google.com/gview?url=${encodeURIComponent(fileUrl)}&embedded=true&random=${Date.now()}`;

      const titles = {
        loa: 'Letter of Agreement',
        privacy_policy: 'Privacy Policy',
      }

      this.document_title = titles[fileType] || ''
      this.view_document_popup = true
    },

  },
}
</script>
